// External Dependencies
import { Container } from '@mui/material';
import React from 'react';

// Internal Dependencies
// import EarlyAccessButton from './shared/EarlyAccessButton';
// import GetUpdatesButton from './shared/GetUpdatesButton';
import HeaderBrand from './HeaderBrand';

// Component Definition
const Header = () => (
  <header className="alt" id="header">
    <HeaderBrand isMainPage />

    <Container maxWidth="sm">
      <p>
        The new way to run your fine arts organization.
        {' '}
        It’s faster, simpler, and more reliable than traditional tools.
      </p>
    </Container>

    <ul className="actions" id="header-actions">
      {/* <li>
        <GetUpdatesButton />
      </li> */}
      {/* <li>
        <EarlyAccessButton />
      </li> */}
      <li>
        <a
          className="button special"
          href={process.env.GATSBY_APP_URL}
        >
          Sign In
        </a>
      </li>
      <li>
        <a
          className="button special-2"
          href={`${process.env.GATSBY_APP_URL}/signup`}
          rel="noreferrer noopener"
          target="_blank"
        >
          Free Trial
        </a>
      </li>
    </ul>
  </header>
);

export default Header;
