// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

// Local Variables
const Element = props => (props.children);

// Component Definition
class Scroll extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    element: PropTypes.string,
    offset: PropTypes.number,
    timeout: PropTypes.number,
    type: PropTypes.string,
  };

  constructor() {
    super();
    this.handleClick = this.handleClick;
  }

  componentDidMount() {
    smoothscroll.polyfill();
  }

  handleClick = (e) => {
    e.preventDefault();
    let elem = 0;
    let scroll = true;
    const {
      type, element, offset, timeout,
    } = this.props;
    if (type && element) {
      switch (type) {
        case 'class':
          [elem] = document.getElementsByClassName(element);
          scroll = !!elem;
          break;
        case 'id':
          elem = document.getElementById(element);
          scroll = !!elem;
          break;
        default:
      }
    }
      scroll ? ( this.scrollTo(elem, offset, timeout) ) : console.log(`Element not found: ${element}`); // eslint-disable-line
  }

  scrollTo = (element, offSet = 0, timeout = null) => {
    const elemPos = element ? element.getBoundingClientRect().top + window.pageYOffset : 0;
    if (timeout) {
      setTimeout(() => { window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' }); }, timeout);
    } else {
      window.scroll({ top: elemPos + offSet, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Element>
        {typeof (children) === 'object' ? (
          React.cloneElement(
            children,
            {
              onClick: this.handleClick,
            },
          )
        ) : (
          <span // eslint-disable-line
            // this span is intentionally not accessible,
            // as it only scrolls to another part of the page
            onClick={this.handleClick}
          >
            {children}
          </span>
        )}
      </Element>
    );
  }
}

export default Scroll;
