// External Dependencies
import React, { useMemo } from 'react';
import Scrollspy from 'react-scrollspy';
import { Box } from '@mui/material';

// Internal Dependencies
import Scroll from './Scroll';
import { banner, promotionBanner } from '../utils/constants/styles';

// Component Definition
const Nav = () => {
  const showPromotionBanner = process.env.GATSBY_APP_SHOW_PROMOTION_BANNER === 'true';
  const showRaffleBanner = process.env.GATSBY_APP_SHOW_RAFFLE_BANNER === 'true';

  const top = useMemo(() => {
    if (showPromotionBanner) {
      return promotionBanner.height;
    }

    if (showRaffleBanner) {
      return banner.height;
    }

    return 0;
  }, [showPromotionBanner, showRaffleBanner]);

  return (
    <Box
      className="nav-links-container alt"
      // eslint-disable-next-line no-nested-ternary
      sx={{ top }}
    >
      <nav id="nav">
        <Scrollspy
          currentClassName="is-active"
          items={['intro', 'first', 'second', 'third', 'ctas']}
          offset={-300}
        >
          <li>
            <Scroll type="id" element="intro">
              <span>Overview</span>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="first">
              <span>About</span>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="second">
              <span>Features</span>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="third">
              <span>Why Us</span>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="ctas">
              <span>Pricing</span>
            </Scroll>
          </li>
        </Scrollspy>
      </nav>
      <div className="hidden animate-in">
        <a
          className="button special-2 short"
          href={`${process.env.GATSBY_APP_URL}/signup`}
          rel="noreferrer noopener"
          target="_blank"
        >
          Free Trial
        </a>
      </div>
    </Box>
  );
};

export default Nav;
