// External Dependencies
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import HomeSectionHeader from './HomeSectionHeader';

// Local Variables
const propTypes = {
  children: PropTypes.node,
  footerActions: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  special: PropTypes.bool,
  spotlight: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

// Component Definition
const HomeSection = ({
  children = null,
  footerActions = null,
  id,
  image = null,
  special = true,
  spotlight,
  subtitle = null,
  title,
}) => (
  <section
    className={special ? 'main special' : 'main'}
    id={id}
  >
    {spotlight ? (
      <div className="spotlight">
        <div className="content">
          <HomeSectionHeader
            subtitle={subtitle}
            title={title}
          />
          {children}
        </div>
        {image && (
          <span className="image">
            <img src={image} alt="" />
          </span>
        )}
      </div>
    ) : (
      <>
        <HomeSectionHeader
          subtitle={subtitle}
          title={title}
        />
        {children}
      </>
    )}
    {footerActions && (
      <footer className="major">
        <ul className="actions">
          {footerActions.map((action) => {
            const buttonClass = action.special
              ? 'button special-2'
              : 'button special';
            return (
              <li key={`${action.text}-${action.path}`}>
                {action.external ? (
                  <a
                    className={buttonClass}
                    href={action.path}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {action.text}
                  </a>
                ) : (
                  <Link
                    className={buttonClass}
                    to={action.path}
                  >
                    {action.text}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </footer>
    )}
  </section>
);

HomeSection.propTypes = propTypes;

export default HomeSection;
