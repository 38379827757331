// External Dependencies
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';

// Internal Dependencies
import Banner from '../components/Banner';
import Header from '../components/Header';
import HomeSection from '../components/shared/HomeSection';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import Nav from '../components/Nav';
import PriceCard from '../components/shared/PriceCard';
import PromotionBanner from '../components/PromotionBanner';
import Quote from '../components/shared/Quote';
import { shortQuoteList } from '../utils/constants/testimonials';
import { banner, promotionBanner } from '../utils/constants/styles';

// Local Variables
const discountPrice = 24900;
const fullPrice = 29900;

const HARD_CODED_PLANS = [
  {
    description: 'Try it free.',
    forList: [
      'Send up to 25 emails',
      'Limited access to all features',
      'Account never expires',
    ],
    id: 1,
    label: 'Free',
  },
  {
    description: 'Covers all your bases.',
    forList: [
      'Unlimited emails',
      'Full access to all features',
      'Unlimited file storage',
    ],
    id: 2,
    label: 'Presto',
    mostPopular: true,
    priceInCents: fullPrice,
  },
  {
    description: 'For large districts.',
    forList: [
      'For 25+ licenses',
      'All features included',
      'Concierge onboarding',
    ],
    id: 3,
    label: 'Presto +',
    priceInCents: discountPrice,
    strikethroughPrice: fullPrice,
  },
];

// Image URLs
const cloudinaryHref = 'https://res.cloudinary.com/presto-assistant/image/upload';
// We include the folder in the file name
const desktopFileName = '2022-03-14--web-app-desktop_j0fk0g.png';
const mobileFileName = '2022-03-14--web-app-mobile_oeticr.png';

const desktopImageSrc = `${cloudinaryHref}/f_auto,q_auto/${desktopFileName}`;
const mobileImageSrc = `${cloudinaryHref}/f_auto,q_auto/${mobileFileName}`;

const desktopImageSrcSet = `${cloudinaryHref}/f_auto,q_auto,w_2040/${desktopFileName} 2040w,
  ${cloudinaryHref}/f_auto,q_auto,w_680/${desktopFileName} 680w,
  ${cloudinaryHref}/f_auto,q_auto,w_400/${desktopFileName} 400w,
`;
const mobileImageSrcSet = `${cloudinaryHref}/f_auto,q_auto,w_2040/${mobileFileName} 2040w,
  ${cloudinaryHref}/f_auto,q_auto,w_680/${mobileFileName} 680w,
  ${cloudinaryHref}/f_auto,q_auto,w_400/${mobileFileName} 400w,
`;

// Component Definition
const Index = () => {
  const showPromotionBanner = process.env.GATSBY_APP_SHOW_PROMOTION_BANNER === 'true';
  const showRaffleBanner = process.env.GATSBY_APP_SHOW_RAFFLE_BANNER === 'true';

  return (
    <Layout>
      <Meta title="Home" />

      {showPromotionBanner && <PromotionBanner />}
      {showRaffleBanner && <Banner />}

      <Nav />

      {showPromotionBanner && <Box height={promotionBanner.height} />}
      {showRaffleBanner && <Box height={banner.height} />}

      <Header />

      <div id="main">
        <HomeSection id="intro" title="Spend your time and energy teaching." special>
          <p>
            {`Growing a music, theater, or dance program is so much work,
                with so little time. Fine Arts teachers need help managing
                the business side of a program — sending emails, keeping inventory,
                tracking financials — so they can use more of their time
                teaching and inspiring kids.`}
          </p>
          <p>
            {`At Presto, we’re dedicated to serving your arts program’s
                logistical and financial needs with fast and reliable leading
                edge technologies, modern easy-to-use design,
                and impeccable customer support.`}
          </p>
        </HomeSection>

        <HomeSection id="first" title="Like an old friend, Presto is...">
          <ul className="features">
            <li>
              <span className="icon major style1 fa-tablet" />
              <h3>Accessible</h3>
              <p>
                You’re covered on any device with our state-of-the-art web app, so
                you can take care of business&nbsp;anywhere.
              </p>
            </li>
            <li>
              <span className="icon major style3 fa-cogs" />
              <h3>Reliable</h3>
              <p>
                Cutting-edge infrastructure means no downtime or slowdown,
                even during the busiest times of the&nbsp;year.
              </p>
            </li>
            <li>
              <span className="icon major style5 fa-phone" />
              <h3>Helpful</h3>
              <p>
                Your satisfaction is incredibly important to us. You can
                expect a response to any concern within&nbsp;24&nbsp;hours.
              </p>
            </li>
          </ul>

          <figure className="article-figure">
            <p>
              <img
                alt="Screenshot of the Presto Assistant web application Dashboard page."
                className="mobile-screenshot"
                sizes="(max-width: 680px) 270px, 680px"
                src={mobileImageSrc}
                srcSet={`${mobileImageSrcSet}`}
              />
            </p>
            <figcaption className="article-figure-caption">
              <span>
                Use Presto on-the-go to keep your program running
              </span>
            </figcaption>
          </figure>
        </HomeSection>

        <HomeSection
          id="second"
          subtitle="See the wonders Presto can do for your to-do list:"
          title="Let us work our magic."
        >
          <ul className="statistics">
            <li>
              <div className="style1">
                <strong>Email</strong>
              </div>
              <p>Communicate to all your students and parents in minutes.</p>
            </li>
            <li>
              <div className="style2">
                <strong>Easy Parent Login</strong>
              </div>
              <p>
                From one login, parents can make online payments and update
                info for all their children.
              </p>
            </li>
            <li>
              <div className="style3">
                <strong>Inventory</strong>
              </div>
              <p>Keep a watchful eye on all your equipment and materials.</p>
            </li>
            <li>
              <div className="style4">
                <strong>Finances</strong>
              </div>
              <p>Easily track money coming into your program.</p>
            </li>
            <li>
              <div className="style4">
                <strong>Online Payments</strong>
              </div>
              <p>Allow parents to pay fees within Presto using Stripe or Vanco.</p>
            </li>
            <li>
              <div className="style5">
                <strong>Library</strong>
              </div>
              <p>
                Find whatever music or scripts you want from your collection
                in a snap.
              </p>
            </li>
            <li>
              <div className="style5">
                <strong>Forms</strong>
              </div>
              <p>
                Create forms directly in Presto and assign them to students and parents.
              </p>
            </li>
            <li>
              <div className="style6">
                <strong>And more...</strong>
              </div>
              <p>Additional features — based on user feedback — shipping out constantly.</p>
            </li>
          </ul>

          <Link
            id="see-more-features-link"
            to="/features"
          >
            <button
              className="special"
              id="see-more-features-button"
              type="button"
            >
              See More Features
            </button>
          </Link>

          <figure className="article-figure">
            <p>
              <img
                alt="Screenshot of the Presto Assistant web application Dashboard page."
                className="desktop-screenshot"
                sizes="(max-width: 680px) 270px, 680px"
                src={desktopImageSrc}
                srcSet={`${desktopImageSrcSet}`}
              />
            </p>
            <figcaption className="article-figure-caption">
              <span>
                Presto&apos;s modern interface makes it easy to use
              </span>
            </figcaption>
          </figure>
        </HomeSection>

        <HomeSection id="third" title="Presto, change-o!">
          <p>
            {`Every fine arts program is different, with unique management needs
                — and we’re flexible and agile enough to handle whatever those needs
                may be. Let Presto do the heavy lifting, so you can spend more time
                making a difference in students’ lives.`}
          </p>
        </HomeSection>

        <HomeSection id="fourth" title="What people are saying">
          <div id="quote-container">
            {shortQuoteList.map((quote) => (
              <Quote
                district={quote.district}
                key={quote.name}
                message={quote.message}
                name={quote.name}
                school={quote.school}
                title={quote.title}
              />
            ))}
          </div>

          <Link
            id="see-more-features-link"
            to="/testimonials"
          >
            <button
              className="special"
              id="see-more-features-button"
              type="button"
            >
              See More Testimonials
            </button>
          </Link>
        </HomeSection>

        <HomeSection
          footerActions={[
            {
              external: true,
              path: process.env.GATSBY_APP_URL,
              special: false,
              text: 'Sign In',
            },
            {
              external: true,
              path: `${process.env.GATSBY_APP_URL}/signup`,
              special: true,
              text: 'Free Trial',
            },
          ]}
          id="ctas"
          title="Pricing Options"
        >
          <ul className="prices">
            {HARD_CODED_PLANS.map((plan) => (
              <li key={plan.id}>
                <PriceCard
                  borderClassName={`price-card-border-${plan.id}`}
                  hideCta
                  plan={plan}
                />
              </li>
            ))}
          </ul>
        </HomeSection>
      </div>
    </Layout>
  );
};

export default Index;
