// External Dependencies
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  borderClassName: PropTypes.string.isRequired,
  hideCta: PropTypes.bool,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    forList: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    mostPopular: PropTypes.bool,
    priceInCents: PropTypes.number,
    strikethroughPrice: PropTypes.number,
  }),
};

const defaultProps = {
  hideCta: false,
};

const displayPrice = (priceInCents) => {
  const priceInDollars = priceInCents / 100;
  return `$${priceInDollars}`;
};

// Component Definition
const PriceCard = ({
  borderClassName, hideCta, plan,
}) => (
  <Card className={`price-card ${borderClassName}`}>
    {plan.mostPopular && (
      <Chip
        className="most-popular-chip"
        color="primary"
        label="Most Popular"
        variant="contained"
      />
    )}
    <CardContent className="card-content">
      <h2>{plan.label}</h2>
      <h1>
        {plan.strikethroughPrice && (
          <span className="strikethrough-price">
            {displayPrice(plan.strikethroughPrice)}
          </span>
        )}
        {plan.id === 1 && '$0'}
        {plan.id !== 1 && displayPrice(plan.priceInCents)}
      </h1>
      <h4>
        {plan.id !== 1
          ? 'per year / per organization'
          : 'for 30 days'}
      </h4>
      <h3>{plan.description}</h3>

      <hr />

      {plan.forList && (
        <ul>
          {plan.forList.map((item) => (
            <li key={item}>
              <i aria-hidden="true" className="fa fa-check" />{' '}
              <span className="for-list-item">{item}</span>
            </li>
          ))}
        </ul>
      )}

      {!hideCta && (
        <a
          className="button special-2"
          href={`${process.env.GATSBY_APP_URL}/register?plan_id=${plan.id}`}
        >
          Free Trial
        </a>
      )}
    </CardContent>
  </Card>
);

PriceCard.propTypes = propTypes;
PriceCard.defaultProps = defaultProps;

export default PriceCard;
