// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

// Component Definition
const HomeSectionHeaders = ({
  title,
  subtitle = null,
}) => (
  <header className="major">
    <h2>
      {title}
    </h2>
    {subtitle && (
    <p>{subtitle}</p>
    )}
  </header>
);

HomeSectionHeaders.propTypes = propTypes;

export default HomeSectionHeaders;
